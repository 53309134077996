import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { BookmarkModule, ChipsModule, TooltipModule, kuerzeHtml } from '@infosysbub/ng-lib-dpl3';
import { MaxLength } from '../../../../../../pipes/maxlength.pipe';
import { FlaggenService } from '../../../../../../services/flaggen.service';
import { MerklistService } from '../../../../../../services/merklist/merklist.service';
import { SignetComponent } from '../../../../../../ui-components/signet/signet.component';
import { Auswahl } from '../../../../services/model/Auswahl';
import { Studienangebot } from '../../../../services/model/Studienangebot';
import { UrlParamService } from '../../../../services/url-param.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ba-studisu-studienangebot',
    templateUrl: './studienangebot.component.html',
    styleUrls: ['./studienangebot.component.scss'],
    imports: [
        NgIf,
        RouterLink,
        TooltipModule,
        BookmarkModule,
        SignetComponent,
        NgFor,
        ChipsModule,
        AsyncPipe,
        MaxLength
    ]
})
export class StudienangebotComponent {
  constructor(
    private urlService: UrlParamService,
    public flaggenService: FlaggenService,
    private sanitizer: DomSanitizer,
    public merklistService: MerklistService
  ) {}

  @Input()
  public studienangebot: Studienangebot;

  @Input()
  public showRemoveFromMerklist: boolean = false;

  private static replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  public getStudienangebotQueryParams(): {} {
    return this.urlService.getQueryParams();
  }

  /**
   * Fügt manuelle Umbrüche nach Schrägstrichen ein.
   */
  breakUpLongWords(word: string): string {
    return word.replace(new RegExp('/', 'g'), '/<wbr>');
  }

  formatText(html: string): SafeHtml {
    html = this.sanitize(html);

    html = StudienangebotComponent.replaceAll(html, '<ul>', '<ul style="margin: 0">');
    // h4 wird auf p geändert, weil's das besser aussieht und die hierarchie
    // für JAWS nicht beeinflusst.
    html = StudienangebotComponent.replaceAll(html, '<h4>', '<h3 style="all: unset">');
    html = StudienangebotComponent.replaceAll(html, '</h4>', '</h3>');

    html = kuerzeHtml(html, 300, 5);

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  sanitize(html?: string): string | undefined {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  iconForStudientyp(studientyp: Auswahl): string {
    // Vgl. de.ba.bub.studisu.studienangebote.model.facetten.StudientypFacettenOption.class
    switch (studientyp.id) {
      case 0:
        return 'studisu-icon-grundständig';
      case 1:
        return 'studisu-icon-weiterführend';
      default:
        return 'ba-icon-certificate';
    }
  }

  iconForStudienform(studienform: Auswahl): string {
    // Vgl. de.ba.bub.studisu.studienangebote.model.facetten.StudienformFacettenOption.class
    switch (studienform.id) {
      case 0:
        return 'studisu-icon-anfrage';
      case 1:
        return 'studisu-icon-vollzeitstudium';
      case 2:
        return 'studisu-icon-teilzeitstudium';
      case 3:
        return 'studisu-icon-wochenendveranstaltung';
      case 4:
        return 'studisu-icon-fernstudium';
      case 5:
        return 'studisu-icon-selbststudium';
      case 6:
        return 'studisu-icon-block';
      default:
        return 'ba-icon-diploma';
    }
  }
}
